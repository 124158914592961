@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

header {
    text-align: center;
    font-size: 16px;
}

main .contact-form {
    margin-top: 2%;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
}

.contact-form label {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    text-align: left;
}

.contact-form input[type="text"], .contact-form input[type="email"]{
    margin-top: 10px;
    width:20%;
    padding: 10px;
    color: rgb(35, 35, 34);
    margin-left: 10px;
}

.contact-form textarea {
    width:40%;
    height:100px;
    margin-left: 10px;
    margin-top: 10px;
}

.contact-form input[type="submit"] {
    margin-top: 20px;
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 14px;
    width: 20%;
    background-color: #4aa3c3;
    font-family: 'Montserrat', sans-serif;
}
