.article {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-top: 50px;
  }
  
  .title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .content {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
  }
  