@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

body {
    background-color: #cde3f2
}

.header {
    margin-bottom: 2px;
}

.page-title {
    font-size: 2.5em;
    color: #333;
}

.blue-text {
    color: blue;
}

.red-text {
    color: red;
}

.main-content {
    max-width: 800px;
    font-size: 1.2em;
    line-height: 1.5;
    color: rgb(35, 35, 34);
    text-align: left;
    margin: 0 10%;
}

.intro-section {
    margin-bottom: 50px;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
}

.cta-button {
    background-color: #4aa3c2;
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 190px;
}

.cta-button:hover {
    background-color: #155575
}
