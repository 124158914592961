@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.title {
    text-align: center;
}

.planSelection {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.payButton {
    background-color: #3151a3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'Montserrat', sans-serif;
}

.payButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.payButton:hover:not(:disabled) {
    background-color: #3151a3;
}

.cardElement {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
}


.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
  

.cancelButton {
    font-family: 'Montserrat', sans-serif;
    background-color: #d52837;
}

.errorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}

.goodContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: green;
}


.notSignedIn {
    text-align: center;
  }