header {
    text-align: center;
    font-size: 16px;
}

main section{
    text-align: left;
    margin: 0 10%;
    
}

main section p {
    color: rgb(35, 35, 34);
}