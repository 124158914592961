@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background-color: #155776;
    color: #fff;
}

.navbarTitle {
    margin: 0;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.navbarButton {
    background-color: #3151a3;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5em 1em;
    margin-left: 0.5em;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'Montserrat', sans-serif;
}

.navbarButton:hover {
    background-color: #243970;
}

.navbarLink {
    text-decoration: none;
    color: inherit;  /* Ensure the link inherits the color of its parent */
    position: relative;  /* Needed to position the pseudo-element correctly */
    margin: 0px 10px;   
}

.navbarLink::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;  /* Adjust the height of the underline as needed */
    bottom: -7px;
    left: 0;
    background-color: #fff;  /* The color of the underline */
    transition: width 0.3s ease-in-out;
}

.navbarLink:hover::after {
    width: 100%;
}

.navbarTitle {
    margin: 0;
}

a {
    
}