.leftButton {
    display: inline-block;
    margin: 0 10px;
    width: 100px;
    background-color: blue;
}

.rightButton {
    display: inline-block;
    margin: 0 10px;
    background-color: red;
    width: 100px;

}

.chatbot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    margin-bottom: 40px;
}

.buttoncontainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
}

.textForm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.inputBox {
    width: 80%; 
    margin-right: 10px;
    margin-bottom: 30px;
}

.submitButton {
    width: 15%; 
}