.header {
    text-align: center;
    font-size: 16px;
    margin-bottom: 2%;
}

.mainSection {
    text-align: left;
    margin: 0 10%;
    background-color: white;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
}

.paragraph {
    color: rgb(35, 35, 34);
    display: none; /* Initially hide the answers */
}

.plusSign {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}
  