.container {
    width: 300px;
    margin: 2em auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-left: 10px;
  }
  
  button {
    padding: 10px;
    color: #fff;
    background-color: #d62739;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .signUpItem {
    width: 100%;
    display: flex;
    align-items: baseline;
  }

  .signUpItem input {
    justify-content: right;
    width: 100%;
  }

  .signUpItem label{
      width: 40%;
  }

  .ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ellipsis div:nth-child(1) {
    left: 8px;
    animation: ellipsis1 0.6s infinite;
  }
  .ellipsis div:nth-child(2) {
    left: 8px;
    animation: ellipsis2 0.6s infinite;
  }
  .ellipsis div:nth-child(3) {
    left: 32px;
    animation: ellipsis2 0.6s infinite;
  }
  .ellipsis div:nth-child(4) {
    left: 56px;
    animation: ellipsis3 0.6s infinite;
  }
  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  