body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1 0 auto;
}

.Footer {
    flex-shrink: 0;
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    justify-content: space-between;
    gap: 20px;  
    align-items: center;
    border-top: 1px solid hsl(0, 0%, 20%);
    padding: 10px 20px;
}

.link {
    color: black; /* Or any color you prefer */
    text-decoration: none;
}

.link:visited {
    color: black; /* Or any color you prefer */
}

.link:hover {
    color: black; /* Or any color you prefer */
    text-decoration: underline;
}
