.comments-section {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.comment-form {
    display: flex;
    margin-bottom: 20px;
}

.comment-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.comment-submit {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.comment-list {
    list-style-type: none;
    padding-left: 0;
}

.comment-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
}

.comment-text {
    margin: 0;
}
