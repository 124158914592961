/* Profile.module.css */
.container {
    width: 300px;
    margin: 2em auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 10px;
    color: #fff;
    background-color: #d52837;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }

  .goodMessage {
    color: green;
    margin-bottom: 10px;
  }
  
  .profileItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .profileItem label {
    margin-right: 10px;
  }
  

  .notSignedIn {
    text-align: center;
  }