@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

body {
  background-color: #eee;
  font-family: 'Montserrat', sans-serif;
}

.container {
  flex-wrap: wrap;
  margin: 50px 100px;
}


.App-header {
    text-align: justify;
}

.App-header h1, .App-header h2{
    text-align: left;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.title {
  grid-column: 1 / span 2;
}

.text-input {
  grid-column: 1;
}

.btn-submit {
  grid-column: 2;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  margin-top: 20px;
}

.title {
  color: black;
  text-align: center;
  width: 100%;
  font-weight: bolder;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin: 1em 0px;
}



.text-input {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #2f4f4f;
}

.btn-submit {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #d52837;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .container {
    margin: 50px 50px;
  }
}
@media (max-width: 900px) {
  .container {
    display:block;
  }
}

.btn-submit:hover {
  background-color: #3151a3;
}

.article {
  width: 80%;
  margin: auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.title {
  color: black;
  text-align: center;
  width: 100%;
  font-weight: bolder;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin: 1em 0px;
}


.subtitle {
  font-size: 1.4em;
  text-align: justify;
  color: black;
  margin-bottom: 1em;
}

.date {
  font-size: 0.8em;
  color: black;
  margin-bottom: 2em;
  text-align: justify;
  font-weight: bold;
}

.content {
  font-size: 1em;
  text-align: justify;
  line-height: 1.6em;
  margin-bottom: 1em;
}

.bias {
  font-size: 1em;
  font-weight: bold;
  text-align: right;
  margin-bottom: 1em;
}

.references {
  font-size: 0.8em;
  color: #999;
  margin-top: 2em;
  border-top: 1px solid #eee;
  padding-top: 1em;
}

.reference {
  padding-left: 25px;
  text-indent: -25px;
}

.saveInput {
  margin-right: 10px;
  margin-left: 0px;
}

.saveMessage {
  color: green;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.errorMessage {
  color: red;
  font-weight: bold;
}

.notSignedIn {
  text-align: center;
}